import React from "react";
import { Grid } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Layout from '../../staticcomponents/layout'
import LetsTalk from '../../staticcomponents/lets-talk'
import './pressRelease.scss';
import LoadMoreButton from '../../staticcomponents/LoadMoreButton';
import press_1 from '../../assets/Press-release-1.png';
import press_2 from '../../assets/Press-release-2.png';
import press_3 from '../../assets/Press-release-3.png';
import press_4 from '../../assets/Press-release-4.png';
import press_5 from '../../assets/Press-release-5.png';
import press_6 from '../../assets/Press-release-6.png';

export default function PressRelease(){
    return(
        <>
         <Layout>
          <Grid className="pressRelease">
            <Grid className="donute-bg-right">
            <Grid className="capsules-bg-right">
            <Container maxWidth="xl" className="pressRelease-Container">
            <Grid container>
              <Grid item xl={2} lg={2}>
                &nbsp;
              </Grid>
              <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                   <Grid item xl={10} lg={10} md={9} sm={10} xs={9}>
                        <h1 className="PressRelease-heading">press Release</h1>
                   </Grid>
                   <Grid container className="press-item">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                        <img src={press_1} alt="pressImage_1" className="pressImage" />
                        </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                            <p className="title">IRS Increases Business Mileage Rate Effective July 2022</p>
                            <p className="date">JUNE 14, 2022</p>
                            <p className="des">Last week, the Internal Revenue Service announced a 4-cent increase in both the standard business mileage rate and the move mileage rate effective July 1. For business miles traveled January 1 through June 30, the standard rate is 58.5 cents a mile.</p>
                            </div>
                        </Grid>
                   </Grid>
                   <Grid container className="press-item">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                        <img src={press_2} alt="pressImage_1" className="pressImage" />
                        </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                            <p className="title">Cartus Accelerates Strategic Growth Plans with Major Client Signings and Expansions; Celebrates Top Ranking in Respected Industry Survey</p>
                            <p className="date">JUNE 12, 2022</p>
                            <p className="des">Cartus places #1 in Average Score among large providers in 6 out of 7 categories in long-running Relocation Managers’ Survey</p>
                            </div>
                        </Grid>
                   </Grid>
                   <Grid container className="press-item" direction="row-reverse">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                        <img src={press_3} alt="pressImage_1" className="pressImage" />
                        </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div className="press-release-content-div">
                            <p className="title">Cartus Accelerates Strategic Growth Plans with Major Client Signings and Expansions; Celebrates Top Ranking in Respected Industry Survey</p>
                            <p className="date">JUNE 12, 2022</p>
                            <p className="des">Cartus places #1 in Average Score among large providers in 6 out of 7 categories in long-running Relocation Managers’ Survey</p>
                            </div>
                        </Grid>
                   </Grid>
                   <Grid container className="press-item" direction="row-reverse">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                        <img src={press_4} alt="pressImage_1" className="pressImage" />
                        </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                            <p className="title">Cartus Accelerates Strategic Growth Plans with Major Client Signings and Expansions; Celebrates Top Ranking in Respected Industry Survey</p>
                            <p className="date">JUNE 12, 2022</p>
                            <p className="des">Cartus places #1 in Average Score among large providers in 6 out of 7 categories in long-running Relocation Managers’ Survey</p>
                            </div>
                        </Grid>
                   </Grid>
                   <Grid container className="press-item">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                        <img src={press_5} alt="pressImage_1" className="pressImage" />
                        </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                            <p className="title">Cartus Accelerates Strategic Growth Plans with Major Client Signings and Expansions; Celebrates Top Ranking in Respected Industry Survey</p>
                            <p className="date">JUNE 12, 2022</p>
                            <p className="des">Cartus places #1 in Average Score among large providers in 6 out of 7 categories in long-running Relocation Managers’ Survey</p>
                            </div>
                        </Grid>
                   </Grid>
                   <Grid container className="press-item">
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                        <img src={press_6} alt="pressImage_1" className="pressImage" />
                        </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="press-release-content-div">
                            <p className="title">Cartus Accelerates Strategic Growth Plans with Major Client Signings and Expansions; Celebrates Top Ranking in Respected Industry Survey</p>
                            <p className="date">JUNE 12, 2022</p>
                            <p className="des">Cartus places #1 in Average Score among large providers in 6 out of 7 categories in long-running Relocation Managers’ Survey</p>
                            </div>
                        </Grid>
                   </Grid>
              </Grid>
              <Grid item xl={2} lg={2}>
                 &nbsp;
              </Grid>
            </Grid>

            {/* Load More Butotn Starts */}
              <LoadMoreButton />
            {/* Load More Butotn Ends */}

            </Container>
            </Grid>
            </Grid>
          </Grid>
          <LetsTalk />
          </Layout>
        </>
    )
}